<template>
   <b-container class="client-dashboard">
      <PageTitle title="Welcome back!" />

      <b-row class="mb-4">
         <b-col md="6" cols="12">
            <b-card id="progress-card" class="info-card h-md-100 mb-4 mb-md-0">
               <h3 class="mb-3">Your Progress</h3>
               <b-progress
                  :value="totalProgress.total"
                  :max="100"
                  variant="white"
                  class="progress-white mb-2"
               ></b-progress>
               <span id="progress-percentage">
                  {{ totalProgress.total }}% Complete
                  {{ openStudies.length > 1 ? `for ${openStudies.length} open studies` : '' }}
               </span>

               <div v-if="openStudies.length > 1" class="study-prog-container">
                  <div
                     v-for="study in openStudies"
                     :key="`study-prog-${study.id}`"
                     class="study-prog"
                  >
                     <span class="mr-2">
                        {{ study.label }} - {{ studyProgress(study.id).total }}%
                     </span>
                     <b-progress
                        :value="studyProgress(study.id).total"
                        :max="100"
                        variant="white"
                        class="progress-white mb-2"
                     ></b-progress>
                  </div>
               </div>
            </b-card>
         </b-col>
         <b-col md="6" cols="12">
            <b-card id="continue-card" class="info-card h-100">
               <h3 class="mb-3">
                  {{ continueCardTitle }}
               </h3>
               <p v-if="nextAction">
                  {{ nextAction.text }}
               </p>
               <p v-else-if="totalProgress < 100">
                  Thank you, all your work is complete. We will notify you when your attention is
                  needed again.
               </p>
               <p v-else>
                  Thank you. We will review your information and contact you when your credit is
                  ready, or if there are any questions.
               </p>
               <div class="d-flex justify-content-end">
                  <b-button
                     id="btn-continue"
                     v-if="nextAction"
                     variant="success"
                     @click="toAction(nextAction.to, nextAction.studyId)"
                  >
                     Continue
                  </b-button>
               </div>
            </b-card>
         </b-col>
      </b-row>

      <!-- ATTENTION CARD -->
      <b-card style="margin-bottom: 2rem">
         <h2 class="text-danger mb-3">For your attention</h2>

         <b-table
            :fields="actionFields"
            :items="priorityActionItems"
            sort-icon-left
            id="table-attention"
            class="mb-0 scrollbar"
            show-empty
            responsive
            sticky-header="500px"
         >
            <template #cell(text)="data">
               <div class="d-flex align-items-center">
                  <b-icon-exclamation-circle-fill variant="danger" font-scale="1.5" class="mr-2" />
                  <span v-if="data.item.textHtml" v-html="data.value"></span>
                  <span v-else>{{ data.value }}</span>
               </div>
            </template>
            <template #cell(actions)="data">
               <div class="cell-w-buttons d-flex justify-content-end">
                  <b-button
                     :id="`btn-attention-${data.item.to.name}`"
                     variant="success"
                     class="no-wrap d-flex align-items-center"
                     size="sm"
                     @click="toAction(data.item.to, data.item.studyId)"
                  >
                     {{ data.item.buttonText }}
                     <custom-icon
                        icon="icon-chevron-right"
                        height="16px"
                        width="16px"
                        class="ml-1"
                     />
                  </b-button>
               </div>
            </template>

            <template #empty>
               <div class="text-center py-4">You have no active items</div>
            </template>
         </b-table>
      </b-card>

      <!-- EMPLOYEE TIME CARD -->
      <CustomerDashboardCard
         ident="time-survey"
         title="Employee Time"
         icon-name="icon-chronometer"
         :progress="totalProgress.timeSurvey"
         :action-items="timeSurveyActionItems"
         :completed-action-items="timeSurveyCompletedItems"
      ></CustomerDashboardCard>

      <!-- CONTRACTOR CARD -->
      <CustomerDashboardCard
         ident="contractor"
         title="Contract Research"
         icon-name="icon-contractor"
         :progress="totalProgress.contractors"
         :action-items="contractorActionItems"
         :completed-action-items="contractorCompletedItems"
      ></CustomerDashboardCard>

      <!-- SUPPLIES CARD -->
      <CustomerDashboardCard
         ident="supplies"
         title="Supplies"
         icon-name="icon-supplies"
         :progress="totalProgress.supplies"
         :action-items="suppliesActionItems"
         :completed-action-items="suppliesCompletedItems"
      >
         <template #header>
            <b-alert :show="suppliesAssigneeName != null" variant="primary" class="mt-3">
               <div class="d-flex align-items-center justify-content-between">
                  <span id="msg-supplies-assignment">
                     This section is currently assigned to <b>{{ suppliesAssigneeName }}</b
                     >.
                  </span>
               </div>
            </b-alert>
         </template>
      </CustomerDashboardCard>

      <!-- CLOUD COMPUTING CARD -->
      <CustomerDashboardCard
         ident="cloud"
         title="Cloud Computing"
         icon-name="icon-cloud-computing"
         :progress="totalProgress.cloud"
         :action-items="cloudActionItems"
         :completed-action-items="cloudCompletedItems"
      >
         <template #header>
            <b-alert :show="cloudAssigneeName != null" variant="primary" class="mt-3">
               <div class="d-flex align-items-center justify-content-between">
                  <span id="msg-cloud-assignment">
                     This section is currently assigned to <b>{{ cloudAssigneeName }}</b
                     >.
                  </span>
               </div>
            </b-alert>
         </template>
      </CustomerDashboardCard>

      <!-- COMPANY CARD -->
      <CustomerDashboardCard
         ident="company"
         title="Company Questionnaires"
         icon-name="icon-office-building"
         :progress="totalProgress.company"
         :action-items="companyActionItems"
         :completed-action-items="companyCompletedItems"
      ></CustomerDashboardCard>

      <!-- PROJECT CARD -->
      <CustomerDashboardCard
         ident="project"
         title="Project Questionnaires"
         icon-name="icon-workflow"
         :progress="totalProgress.project"
         :action-items="projectActionItems"
         :completed-action-items="projectCompletedItems"
      ></CustomerDashboardCard>

      <!-- PROFILE CARD -->
      <CustomerDashboardCard
         ident="profile"
         title="Profiles"
         icon-name="icon-avatar"
         :progress="totalProgress.profile"
      >
         <div
            v-for="(item, idx) in profileActionItems"
            :key="`${item.data.userId}:${item.studyId}`"
            class="d-flex align-items-center justify-content-between pt-3"
         >
            <span :id="`profile-name-${idx + 1}`">
               {{ `${item.data.userFirstName} ${item.data.userLastName}` }}
               {{ openStudies.length > 1 ? `(${studyMap[item.studyId].label})` : '' }}
            </span>
            <div class="d-flex align-items-center">
               <span :id="`profile-percentage-${idx + 1}`" class="mr-2"
                  >{{ profilePercentage(item) }}% Complete</span
               >
               <b-progress
                  :value="profilePercentage(item)"
                  :max="100"
                  style="min-width: 14rem"
                  :variant="
                     item.action === 'PROFILE_QUESTIONNAIRE_FINISHED' ? 'success' : 'primary'
                  "
               >
               </b-progress>
            </div>
         </div>
      </CustomerDashboardCard>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {SidebarStates} from '@/store/modules/session';

import CustomerDashboardCard from './widgets/CustomerDashboardCard';
import {sortBy} from '../../helpers/utils';

export default {
   components: {
      CustomerDashboardCard,
   },

   data() {
      return {
         SidebarStates,
      };
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         greeted: 'greeted',
         manager: 'manager',
         openStudies: 'companies/openStudies',
         studyMap: 'companies/studyMap',
         totalProgress: 'progress/totalProgress',
         studyProgress: 'progress/studyProgress',
         priorityActionItems: 'progress/priorityActionItems',
         timeSurveyActionItems: 'progress/timeSurveyActionItems',
         timeSurveyCompletedItems: 'progress/timeSurveyCompletedItems',
         projectActionItems: 'progress/projectActionItems',
         projectCompletedItems: 'progress/projectCompletedItems',
         companyActionItems: 'progress/companyActionItems',
         companyCompletedItems: 'progress/companyCompletedItems',
         suppliesActionItems: 'progress/suppliesActionItems',
         suppliesCompletedItems: 'progress/suppliesCompletedItems',
         contractorActionItems: 'progress/contractorActionItems',
         contractorCompletedItems: 'progress/contractorCompletedItems',
         cloudActionItems: 'progress/cloudActionItems',
         cloudCompletedItems: 'progress/cloudCompletedItems',
         cloudAssignee: 'cloud/assignee',
         _profileActionItems: 'progress/profileActionItems',
         _suppliesAssignee: 'supplies/assignee',
         _users: 'users/companyUsers',
      }),

      actionFields() {
         const actionFields = [
            {key: 'text', label: 'Incomplete', sortable: true},
            {key: 'actions', label: ''},
         ];

         if (this.openStudies.length > 1) {
            actionFields.unshift({
               key: 'studyId',
               label: 'Study',
               formatter: (value) => this.studyMap[value]?.label,
               sortable: true,
               sortByFormatted: true,
            });
         }

         return actionFields;
      },

      continueCardTitle() {
         if (this.totalProgress === 0) {
            return "Let's get started!";
         } else if (this.nextAction) {
            return 'Continue where you left off';
         } else {
            return 'All done!';
         }
      },

      companyId() {
         return this.profile.companyId;
      },

      nextAction() {
         if (this.priorityActionItems.length === 0) {
            return null;
         } else {
            return this.priorityActionItems[0];
         }
      },

      profileActionItems() {
         const profileActionItems = this._profileActionItems;

         profileActionItems.sort(
            sortBy((i) => `${i.data.userFirstName} ${i.data.userLastName}`.toLocaleLowerCase())
         );

         return profileActionItems;
      },

      suppliesAssigneeName() {
         const assigneeId = this._suppliesAssignee;

         if (assigneeId === null) {
            return null;
         }

         const assigneeUser = this._users[assigneeId];
         return assigneeUser ? `${assigneeUser.firstName} ${assigneeUser.lastName}` : '';
      },

      cloudAssigneeName() {
         if (this.cloudAssignee === null) {
            return null;
         }

         const assignee = this._users[this.cloudAssignee];
         return assignee ? `${assignee.firstName} ${assignee.lastName}` : '';
      },
   },

   methods: {
      /** Percentage complete for an individual profile questionnaire */
      profilePercentage(item) {
         const total = item.data.count;
         let complete = item.data.count - item.data.unanswered.length;
         return Math.floor((complete / total) * 100);
      },

      toAction(route, studyId) {
         if (this.activeStudyId !== studyId) {
            this.$store.commit('companies/setActiveStudy', studyId);
         }
         this.$router.push(route);
      },
   },

   async created() {
      let requests = [
         this.$store.dispatch('progress/loadProgress', {
            companyId: this.companyId,
            allStudies: true,
         }),
         this.$store.dispatch('companies/loadCompany', {companyId: this.companyId}),
         this.$store.dispatch('users/loadCompanyUsers', {companyId: this.companyId}),
      ];

      await this.blockUntilAllSettled(requests);
   },
};
</script>

<style lang="scss" scoped>
.info-card {
   background-color: $gray-800;
   color: $white;
   border: none;

   @include md {
      height: 100%;
   }
}

.study-prog-container {
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   margin-top: 1rem;

   .study-prog {
      display: flex;

      .progress {
         width: 12rem;
      }
   }
}
</style>
